import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dataService from '../../services/dataService';
import { FormattedMessage } from 'react-intl';
import TooltipButton from '../TooltipButton/TooltipButton';

const CarDetails = () => {
    const dispatch = useDispatch();
    const carDetails = useSelector(state => state.carDetails);
    const dealers = useSelector(state => state.dealer);

    const currentCulture = document.documentElement.dataset.culture;
    let country = currentCulture.substring(0, currentCulture.indexOf('-'));
    if (country !== 'da' && country !== 'et' && country !== 'fi' && country !== 'lt' && country !== 'lv' && country !== 'nb' && country !== 'sv')
        country = 'fi';

    const licenseSrc = "/Scripts/src/media/icons/license-plate-" + country + ".svg";

    const showSectionData = dealers.currentDealer;

    let licensePlaceholder = 'ABC-123';
    let mileageUnit = 'Km';

    switch (currentCulture) {
        case 'da-DK':
            licensePlaceholder = 'AB12345';
            break;
        case 'et-EE':
            licensePlaceholder = '123ABC';
            break;
        case 'en-GB':
            licensePlaceholder = 'AB72 CDE';
            break;
        case 'lv-LV':
            licensePlaceholder = 'AB-1234';
            break;
        case 'nb-NO':
            licensePlaceholder = 'AB 12345';
            break;
        case 'sv-SE':
            licensePlaceholder = 'ABC123';
            mileageUnit = 'Mil';
            break;
    }

    useEffect(() => {
        if (dealers.isReturnFromSummaryPage) {
            return;
        }

        if (dealers.currentDealer) {
            fetchVehicleInfo(carDetails, dealers.currentDealer.DealerId);
        }

    }, [dealers, carDetails.license.value, carDetails.mileage.value, carDetails.vinNumber]);

    async function fetchVehicleInfo(carDetails, dealerId) {
        if (carDetails.mileage.error === 'noError' && (carDetails.license.error === 'noError' || carDetails.vinNumber)) {

            dispatch({
                type: 'vehicleSearching',
                payload: true
            });

            const countryCodeFromCulture = currentCulture ? currentCulture.split('-')[1] : 'fi';
            const vehicleFromBackEnd = await dataService.getVehicleInfo(carDetails.license.value, carDetails.vinNumber, carDetails.mileage.value, dealerId, countryCodeFromCulture);
            console.log('vehicleFromBackEnd', vehicleFromBackEnd);

            dispatch({
                type: 'vehicleSearching',
                payload: false
            });

            if (vehicleFromBackEnd !== null && vehicleFromBackEnd !== '') {
                // Convert Swedish Mil to km to ensure correct handling in backend
                vehicleFromBackEnd.Mileage = mileageUnit === 'Mil' ? parseInt(carDetails.mileage.value) * 10 : parseInt(carDetails.mileage.value);

                dispatch({
                    type: 'vehicleFromBackendAdded',
                    payload: vehicleFromBackEnd
                });
            } else {
                dispatch({
                    type: 'vehicleFromBackendNotFound'
                });
            }

        }
    }

    const handleChange = async (field, e) => {

        dispatch({
            type: 'isReturnFromSummaryPage', payload: false
        });

        if (field === 'license') {
            dispatch({
                type: 'licenseInfoChanged', payload: { license: e.target.value, locale: currentCulture }
            });
        } else if (field === 'mileage') {
            dispatch({
                type: 'mileageInfoChanged', payload: e.target.value
            });
        }
        else {
            dispatch({
                type: 'vinNumberChanged', payload: e.target.value
            });
        }
    };

    const toggleMileageTooltipTextVisibility = () => {
        const mileageTooltipTextVisibility = carDetails.mileageTooltipTextVisibility === "hide" ? "show" : "hide";

        dispatch({
            type: 'mileageTooltipTextVisibilityChanged', payload: mileageTooltipTextVisibility
        });
    };

    return (
        <section id="car-details" className="margin-top-m">
            <div className="container">
                {/*                <ReactTooltip id="tooltip" wrapper="span" event="click" />*/}
                <div className="row d-flex">
                    <div className="col-12 col-lg-10 offset-lg-1 bg-w padding-top-m-scale padding-hor-l-scale hr-top">
                        <h2 className="visual-h3">2. <FormattedMessage id="carDetails.header" /></h2>
                        {
                            !showSectionData &&
                            <FormattedMessage id="carDetails.message.giveDealerInfoFirst" />
                            ||
                            <span>
                                <div className="row d-flex">
                                    <div className="col-12 col-md-4 col-xl-3">
                                        <div className="bmw-text-input input-large">
                                            <label htmlFor="license-plate"><FormattedMessage id="carDetails.licensePlate" /></label>
                                            <span className="wrapper">
                                                <div>
                                                    <input
                                                        type="text"
                                                        id="license-plate"
                                                        className={currentCulture}
                                                        placeholder={licensePlaceholder}
                                                        maxLength="7"
                                                        onChange={(event) => handleChange("license", event)}
                                                        value={carDetails.license.value || ''}
                                                        autoFocus
                                                    />
                                                    <img className="license-plate-svg" src={licenseSrc} alt="" />
                                                </div>
                                            </span>
                                            <small className="text-error"><FormattedMessage id={carDetails.license.error} /></small>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-5  col-xl-4" hidden={carDetails.license.error !== 'carDetails.license.error.vehicleNotFound' && !carDetails.vinNumber}>
                                        <div className="bmw-text-input input-large">
                                            <label htmlFor="vin-number"><FormattedMessage id="carDetails.vinNumber" /></label>
                                            <span className="wrapper">
                                                <input
                                                    type="text"
                                                    id="vin-number"
                                                    maxLength="20"
                                                    onChange={(event) => handleChange("vinNumber", event)}
                                                    value={carDetails.vinNumber || ''}
                                                />
                                            </span>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-4 offset-md-1 col-xl-3 padding-top-s-md-down">
                                        <div className="bmw-text-input input-large">
                                            <label htmlFor="mileage">
                                                <FormattedMessage id="carDetails.mileage" />
                                            </label>
                                            <span className="wrapper">
                                                <input
                                                    className="text-right"
                                                    type="number"
                                                    id="mileage"
                                                    size="3"
                                                    maxLength="3"
                                                    min="0"
                                                    max="999"
                                                    placeholder="0"
                                                    onChange={(event) => handleChange("mileage", event)}
                                                    value={carDetails.mileage.value}
                                                />
                                                <span className="mileage-000km">
                                                    {
                                                        currentCulture === 'sv-SE' &&
                                                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> ||
                                                        <span>000 </span>
                                                    }
                                                    </span>
                                                    <span className="type">{mileageUnit}</span>

                                                <TooltipButton tooltipFunction={toggleMileageTooltipTextVisibility} tooltipParameter={null} />
                                            </span>

                                            <small className="text-error"><FormattedMessage id={carDetails.mileage.error} /></small>
                                        </div>

                                        <div className={carDetails.mileageTooltipTextVisibility + " tooltipWrapper"}>
                                            <div className="modal-close_terms_wrapper" >
                                                <span className="modal-close_terms" onKeyPress={() => toggleMileageTooltipTextVisibility()}
                                                    onClick={() => toggleMileageTooltipTextVisibility()} tabIndex="0">X
                                                </span>
                                            </div>
                                            <FormattedMessage id="carDetails.mileage.moreinfo" />
                                        </div>

                                    </div>
                                </div>
                                {
                                    carDetails.vehicleFromBackend &&
                                    <div className="row car-found">{carDetails.vehicleFromBackend.FullModelName && carDetails.vehicleFromBackend.FullModelName + ', '}{carDetails.vehicleFromBackend.ModelSeries}</div>
                                }
                                <div className="row" hidden={carDetails.vehicleSearching === false}><FormattedMessage id="carDetails.vehicleSearching" /></div>
                            </span>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CarDetails;